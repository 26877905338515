<template>
  <v-card :loading="loading" :disabled="loading" class="mt-n10 moholand-shadow">
    <v-card-title>
      ویرایش درخواست برداشت
      <v-chip
        v-if="model.status == 0"
        color="yellow darken-3"
        dark
        class="rounded"
        small
      >
        در حال بررسی
      </v-chip>
      <v-chip
        v-else-if="model.status == 1"
        color="success"
        dark
        class="rounded"
        small
      >
        تایید شده
      </v-chip>
      <v-chip v-else color="red" dark class="rounded" small>
        رد شده
      </v-chip>
      <v-spacer></v-spacer>
      <v-btn color="primary" :to="destinationRoute">
        <v-icon>mdi-undo-variant</v-icon>
        بازگشت
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" @submit.prevent="submit">
        <v-form-base
          :col="{ cols: 12, md: 6 }"
          :model="model"
          :schema="schema"
        />
        <v-row align="center" justify="end" class="mb-3">
          <v-btn
            class="mx-4"
            dark
            color="success"
            type="submit"
            :loading="loading"
          >
            ویرایش درخواست برداشت
          </v-btn>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { Constants } from "@/constants/GlobalConstants";
import MyAxios from "@/constants/MyAxios";
import MySnackbar from "@/components/MySnackbar";
import VFormBase from "vuetify-form-base";
import { getErrorText } from "@/constants/GlobalConstants";
import { formPostPrepare, formGetPrepare } from "@/constants/PanelFormHandler";

export default {
  components: {
    VFormBase,
    MySnackbar,
  },
  created() {
    this.getData();
    this.getUsers();
  },

  data() {
    return {
      id: this.$route.params.id,
      loading: false,
      title: "",
      destinationRoute:
        this.$route.fullPath.slice(
          0,
          this.$route.fullPath.indexOf("edit") - 1
        ) + (this.$route.query?.page ? "?page=" + this.$route.query?.page : ""),
      model: {
        user_id: "",
        price: "",
        desc: "",
        status: -1,
      },
      schema: {
        user_id: {
          type: "CustomInput",
          inputType: "select",
          items: [],
          itemValue: "id",
          itemText: "value",
          label: "کاربر",
          rules: [Constants.rules.required],
          loading: true,
          required: true,
        },
        price: {
          type: "CustomInput",
          label: "قیمت",
          rules: [Constants.rules.required],
          required: true,
        },
        desc: {
          type: "CustomInput",
          inputType: "textarea",
          label: "توضیحات",
          rules: [Constants.rules.required],
          col: 12,
        },
      },
    };
  },

  methods: {
    getData() {
      this.loading = true;
      MyAxios.get("/withdraws/index/" + this.id, {
        params: {
          noPaginate: true,
        },
      })
        .then((response) => {
          console.log(response.data);
          this.price = response.data[0].price;
          this.desc = response.data[0].desc;
          formGetPrepare(response, this.model);
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    getUsers() {
      MyAxios.get("/users/index", {
        params: {
          noPaginate: true,
        },
      })
        .then((response) => {
          console.log(response.data);
          this.schema.user_id = {
            ...this.schema.user_id,
            loading: false,
            items: response.data.map((val) => ({
              id: val.id,
              value: val.first_name + " " + val.last_name,
            })),
          };
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let formData = formPostPrepare(this.model);
        MyAxios.post(`withdraws/${this.id}/update`, formData)
          .then((response) => {
            this.loading = false;
            this.$root.$emit("toast", {
              text: "درخواست برداشت با موفقیت ویرایش شد",
              type: "success",
            });
            this.$router.replace(this.destinationRoute);
          })
          .catch((error) => {
            this.loading = false;
            this.$root.$emit("toast", {
              text: getErrorText(error),
            });
          });
      } else {
        this.$root.$emit("toast", {
          text: "لطفا اطلاعات را به درستی وارد نمایید.",
        });
      }
    },
  },
};
</script>

<style></style>
